const form = $('#fcontact');

$('#r_subject').on('change', function (e) {
    const val = $(this).find('option:selected').val();
    //console.log(val);
    const msg = JSON.parse(Base64.decode($('meta[name="forms.messages"]').attr('content')));
    const route = JSON.parse(Base64.decode($('meta[name="forms.route"]').attr('content')));
    const lang = document.documentElement.lang;
    const subjects = ['aml']; //'aaron'

    /*
     * change header background because of chosen subject
     */
    if (subjects.includes(val)) {
        $('#headerpic').css('background-image', `linear-gradient(rgb(39,35,51,.4), rgba(39,35,51,.4)), url(${msg.bgimg[val]})`);
        $('#headerpic').css('background-position', '0% 30%');
    } else {
        $('#headerpic').css('background-image', `linear-gradient(rgb(39,35,51,.4), rgba(39,35,51,.4)), url(${msg.bgimg.default})`);
        $('#headerpic').css('background-position', '50% 90%');
    }

    /*
     * handling form elements because of chosen subject
     */

// Zwischenlösung Kontaktformular bis AS wieder gangbar bei CQ    
if ('cmqcontract' === val) {
    $('#section-cmqcontract').removeClass('d-none').show();
} else {
    $("#section-cmqcontract").addClass("d-none").hide();
}

    if ('product' === val) {
        $('#section-product').removeClass('d-none').show();
        $('#r_product').prop('required', true);
    }else {
        $("#section-product").addClass("d-none").hide();
        $("#r_product").prop("required", false);}

    if ('repair' === val || 'job' === val || 'warranty' === val) {
        $('#section-file').removeClass('d-none').show();
    } else {
        $('#section-file').addClass('d-none').hide();
    }

    if ('repair' === val || 'warranty' === val) {
        $('#section-ticketno').removeClass('d-none').show();
        $('#section-rmano').removeClass('d-none').show();
        $('#hint-file').html(msg.optional);
    } else {
        $('#section-ticketno').addClass('d-none').hide();
        $('#section-rmano').addClass('d-none').hide();
        $('#hint-file').html('');
    }

    if ('job' === val) {
        $('#r_file').prop('required', true);
        $('#hint-file').html('*');
    } else {
        $('#r_file').prop('required', false);
        $('#hint-file').html();
    }

    if ('partner' === val && 'fr' != lang) {
        $(form)[0].reset();
        location.href = route.partner;
    }

    if ('helpdesk' === val) {
        $(form)[0].reset();
        location.href = route.helpdesk;
    }

    if ('maintenance' === val) {
        $('#section-notes').addClass('d-none').hide();
        $('#r_notes').prop('required', false);
        $('#section-hints').removeClass('d-none').show();
        $('#section-hints').html(msg.description.maintenance);
    } else {
        $('#section-notes').removeClass('d-none').show();
        $('#r_notes').prop('required', true);
        $('#section-hints').addClass('d-none').hide();
        $('#section-hints').html();
    }

    $(form).validator('update');
});

const selected = $("#r_subject :selected").val();
if (selected != '') {
    $('#r_subject').trigger('change');
}


$(".custom-file-input").on('change', function (e) {
    var obj = $(e.currentTarget).attr('id');
    var objno = obj.substring(obj.length - 2);
    //console.log(obj);

    var filename = e.target.files[0].name;
    $("label[for='" + obj + "']").empty().html(filename);
});

$(form).validator().on('submit', function (e) {
    if (e.isDefaultPrevented()) {
        console.log(e);
        return;
    }
    e.preventDefault();

    let btn = $(document.activeElement);
    let todo;
    if (
        btn.length &&
        form.has(btn) &&
        btn.is('button[type="submit"], input[type="submit"], input[type="image"]') &&
        btn.is('[name]')
    ) {
        todo = btn.attr('name');
        btnValue = $(btn).html();
    }

    //console.log("hier weiter");
    let fd = new FormData(this);
    fd.append('lang', document.documentElement.lang);
    $.ajax({
        type: 'POST',
        enctype: 'multipart/form-data',
        url: $(form).attr('action'),
        data: fd,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
        beforeSend: () => {
            //console.log('beforeSend');
            let msg = JSON.parse(Base64.decode($('meta[name="forms.messages"]').attr('content')));
            $(btn).prop('disabled', true).html(msg.wait);
            $('#print-error').addClass('d-none').hide().find('ul').html('');
            $('#print-success').addClass('d-none').hide().html('');
            $(this).find('.is-invalid').removeClass('is-invalid');
        },
        success: (data) => {
            //console.log("SUCCESS: ", data);
            this.reset();
            $('.custom-file-label').html('');
            $('.selectpicker').val('').selectpicker("refresh");
            $('#print-success').removeClass('d-none').show().html(data.message);
        },
        error: (e) => {
            console.log("ERROR:", e);
            if (e.status == 422) {
                //console.log(e.responseJSON.errors);
                //$('#print-error').removeClass('d-none').show().html(e.responseJSON.errors);
                $('#print-error').removeClass('d-none').show().find('ul').html('');
                if ((e.responseJSON).hasOwnProperty('errors')) {
                    Object.entries(e.responseJSON.errors).forEach(entry => {
                        const [key, value] = entry;
                        $('#print-error').find('ul').append('<li>' + value + '</li>');
                        $('#' + key).addClass('is-invalid');
                        //$('#' + key).next('.with-errors').html(value);
                    });
                } else if ((e.responseJSON).hasOwnProperty('message')) {
                    //console.log(typeof(e.responseJSON.message));
                    if (typeof (e.responseJSON.message) === 'string') {
                        $('#print-error').find('ul').append('<li>' + e.responseJSON.message +
                            '</li>');
                    }
                    if (typeof (e.responseJSON.message) === 'object') {
                        Object.values(e.responseJSON.message).forEach(val => {
                            $('#print-error').find('ul').append('<li>' + val + '</li>');
                        });
                    }
                }
            } else {
                //console.log(e.responseJSON.message);
                $('#print-error').removeClass('d-none').show().html(e.responseJSON.message);
                location.reload();
            }
        },
        complete: () => {
            //console.log("COMPLETE");
            $(btn).prop('disabled', false).html(btnValue);
            $("html, body").animate({
                scrollTop: 0
            }, "slow");
        }
    });
})

let startDate = null;
let endDate = null;
let d = new Date();
d.setDate(d.getDate() + 1);
if (d.getDay() == 0) d.setDate(d.getDate() + 1);
else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
d.setHours(8, 15, 0, 0);

$('.input-group.date').datetimepicker({
    locale: 'de',
    date: d,
    daysOfWeekDisabled: [0],
    stepping: 15,
    minDate: d,
    //format: 'YYYY-MM-DD HH:mm',
    format: 'DD.MM.YYYY HH:mm',
    calenderWeeks: true,
    viewMode: 'days',
    ignoreReadonly: true,
    disabledTimeIntervals: [
        [moment({
            h: 0
        }), moment({
            h: 8
        })],
        [moment({
            h: 15
        }), moment({
            h: 24
        })]
    ],
    disabledHours: [0, 1, 2, 3, 4, 5, 6, 7, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    disabledDates: ['2024-03-29', 
                    '2024-04-01', 
                    '2024-05-01',
                    '2024-05-09', 
                    '2024-05-20', 
                    '2024-10-03', 
                    '2024-10-31', 
                    '2024-12-24', 
                    '2024-12-25', 
                    '2024-12-26', 
                    '2024-12-29', 
                    '2024-12-31']
                    ,
    widgetPositioning: {
        horizontal: 'right',
        vertical: 'auto'
    },
    icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'far fa-arrow-up',
        down: 'far fa-arrow-down',
        previous: 'far fa-chevron-left',
        next: 'far fa-chevron-right',
        today: 'far fa-calendar-check',
        clear: 'far fa-trash',
        close: 'far fa-times'
    },
    //inline:true,
    //sideBySide:true,
});

$('#r_pbxowner').on('change', function (e) {
    let val = $(this).find('option:selected').val();

    if ('onbehalf' === val) {
        $('#section-pbxaddress').removeClass('d-none').show();
        $('#r_owner_name').prop('required', true);
        $('#r_owner_street').prop('required', true);
        $('#r_owner_zip').prop('required', true);
        $('#r_owner_town').prop('required', true);
    } else {
        $('#section-pbxaddress').addClass('d-none').hide();
        $('#r_owner_name').prop('required', false);
        $('#r_owner_street').prop('required', false);
        $('#r_owner_zip').prop('required', false);
        $('#r_owner_town').prop('required', false);
    }

    $(form).validator('update');
});
